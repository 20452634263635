const getCoordinatesByAddress = async(address, showError = true) => {
  if (address) {
    const apiKey = useRuntimeConfig().public.googleMapsApiKey;

    const response = await $fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`).catch(() => false);

    if (response?.results?.length === 0 && showError) {
      throw new Error(`Could not find coordinates for address: ${address}`);
    }

    if (response?.results) {
      return response?.results[0]?.geometry?.location;
    }
  }

  return '';
};

export default {
  getCoordinatesByAddress,
};
