<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <GoogleMap
      v-if="locations?.length"
      :api-key="useRuntimeConfig().public.googleMapsApiKey"
      class="w-full h-[26.25rem]"
      :zoom="16"
      :center="firstLocationPosition || 'center'"
      :clickable-icons="false"
      :scrollwheel="false"
    >
      <MarkerCluster>
        <div
          v-for="(location, index) in locations"
          :key="index"
        >
          <CustomMarker
            :options="{
              position: location.position,
              anchorPoint: 'BOTTOM_CENTER',
            }"
            :class="{
              'z-50': location.isOpen,
            }"
          >
            <div
              class="relative"
            >
              <div
                v-if="location.isOpen"
                class="absolute top-0 w-56 md:w-[25rem] left-0 bg-(bg-default) text-(fg-default) rounded p-3.5 pr-9 border border-(border-muted) -translate-x-1/2 -translate-y-[105%]"
              >
                <div
                  class="font-bold text-base"
                  v-html="sanitize(location.title)"
                />
                <div
                  class="text-base text-(fg-muted)"
                  v-html="sanitize(location.place)"
                />
                <button
                  type="button"
                  class="absolute top-3 right-3"
                  @click="location.isOpen = false"
                >
                  <Icon
                    name="x-close"
                    class="w-5 h-5 text-(fg-subtle)"
                  />
                </button>
              </div>
              <button
                type="button"
                class="relative w-10 h-10 bg-(bg-emphasis)"
                @click="location.isOpen = !location.isOpen"
              >
                <div class="absolute w-2.5 h-2.5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-(bg-default)" />
                <div class="bg-(bg-emphasis) w-4 h-4 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45" />
              </button>
            </div>
          </CustomMarker>
        </div>
      </MarkerCluster>
    </GoogleMap>
  </div>
</template>

<script setup>
import { GoogleMap, CustomMarker, MarkerCluster } from 'vue3-google-map';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);

// Refs
const locations = ref([]);
const firstLocationPosition = ref(null);

// Methods
const loadLocations = _debounce(() => {
  locations.value = [];
  firstLocationPosition.value = null;

  section.data?.value.items?.forEach(async(item, index) => {
    const position = await useGeolocation.getCoordinatesByAddress(item.place || 'Madison Square Garden', false);

    if (position) {
      if (!firstLocationPosition.value) {
        firstLocationPosition.value = position;
      }

      locations.value.push({
        title: item[section.lang.value]?.title,
        place: item.place || 'Madison Square Garden',
        position,
        isOpen: index === 0,
      });
    }
  });
}, 500);

// Watchers
watch(() => section.data.value.items, loadLocations, { deep: true });
watch(() => section.lang.value, loadLocations);

// Lifecycle
onMounted(() => {
  loadLocations();
});
</script>
